import { useState } from 'react';

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

export const useServerErrors = () => {
  const [serverErrors, setServerErrors] = useState<Record<string, string>>({});
  const [generalError, setGeneralError] = useState<string | null>(null);

  const processErrors = (errors: ResponseError[]) => {
    const formattedErrors: Record<string, string> = {};
    let generalErr = null;

    errors.forEach((err) => {
      if (err.path) {
        formattedErrors[err.path] = err.msg;
      } else {
        generalErr = err.msg;
      }
    });

    setServerErrors(formattedErrors);
    setGeneralError(generalErr);
  };

  const clearErrors = () => {
    setServerErrors({});
    setGeneralError(null);
  };

  return { serverErrors, generalError, processErrors, clearErrors };
};
