import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';
import { TUser } from '../../../types/User';

function CriteriaListItem({ label, value, criteriaMet }: { label: string; value: string; criteriaMet: boolean }) {
  const IconComponent = criteriaMet ? CheckCircleIcon : XCircleIcon;
  const iconColor = criteriaMet ? 'green' : 'red';
  return (
    <li className="flex flex-row leading-loose">
      <IconComponent className={`h-7 w-7 text-${iconColor}-600 mr-1 inline`} aria-hidden="true" />
      <div>
        <span className="font-semibold text-gray-700">{label}:</span> {value}
      </div>
    </li>
  );
}

export type TEligibilityCriteriaListProps = {
  user: TUser;
};

const percentileRankingForDisplay = function (percentileRanking?: number) {
  if (percentileRanking) {
    return `Top ${100 - parseInt(Math.floor(percentileRanking || 1).toFixed(0))}%`;
  } else {
    return 'Unrated';
  }
};

const EligibilityCriteriaList = (props: TEligibilityCriteriaListProps) => {
  const { user } = props;
  const userScorePercentile = user?.intervieweeScoringV2?.scoreHighestPercentileRank || 0;
  const userScorePercentileDisplay = percentileRankingForDisplay(
    user?.intervieweeScoringV2?.scoreHighestPercentileRank
  );
  const userYOE = user?.types?.yearsExperience;
  let userYOEDisplay = 'Unknown';
  if (userYOE) {
    if (userYOE === -1) {
      userYOEDisplay = 'Student';
    } else if (userYOE === 0) {
      userYOEDisplay = '0 (or not a software engineer)';
    } else if (userYOE < 20) {
      userYOEDisplay = userYOE.toString();
    } else {
      userYOEDisplay = '20+';
    }
  }
  const userVisaStatusDisplay = user?.visa?.needed
    ? window.CONFIG.const.companyIntroductions.ALLOWED_VISA_STATUSES.includes(user?.visa?.needed)
      ? 'Eligible'
      : 'Ineligible'
    : 'Not provided';

  return (
    <ul className="text-sm">
      <CriteriaListItem
        criteriaMet={!!user._unusedCompanyIntroductionTokens}
        label="Introduction tokens"
        value={user._unusedCompanyIntroductionTokens.toString()}
      />
      <CriteriaListItem
        criteriaMet={userScorePercentile >= window.CONFIG.const.companyIntroductions.MIN_SCORE_PERCENTILE}
        label="Highest rating achieved"
        value={userScorePercentileDisplay}
      />
      <CriteriaListItem
        criteriaMet={userYOE >= window.CONFIG.const.companyIntroductions.MIN_YOE}
        label="Years of experience"
        value={userYOEDisplay}
      />
      <CriteriaListItem
        criteriaMet={window.CONFIG.const.companyIntroductions.ALLOWED_VISA_STATUSES.includes(user?.visa?.needed)}
        label="Visa status"
        value={userVisaStatusDisplay}
      />
    </ul>
  );
};

export default EligibilityCriteriaList;
