import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import axios from '../utils/axios';
import { StripeCardElement, Stripe, SetupIntentResult } from '@stripe/stripe-js';

interface AddPaymentMethodParams {
  paymentMethodId: string;
  cardElement?: StripeCardElement;
  stripe: Stripe;
}

const useAddPaymentMethodMutation = (options?: UseMutationOptions<unknown, Error, AddPaymentMethodParams, string>) =>
  useMutation(async (paymentParams: AddPaymentMethodParams): Promise<SetupIntentResult> => {
    const { paymentMethodId, cardElement, stripe } = paymentParams;
    const response = await axios.post('api/orders/addPaymentMethod/', {
      paymentMethodId,
    });

    if (!response.data.clientSecret) {
      window.Rollbar.error('no client secret');
      throw new Error('Failed to obtain client secret from server.');
    }

    const setupIntentResult = stripe.confirmCardSetup(response.data.clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {},
      },
    });

    // If Stripe returns an error, throw it so React Query's onError gets triggered
    if ((await setupIntentResult).error) {
      throw new Error((await setupIntentResult).error.message || 'Payment method setup failed.');
    }
    return setupIntentResult;
  }, options);

export default useAddPaymentMethodMutation;
