import { DedicatedCoachingCalloutCardPropsType } from './Types';

const BLOG_POST_URL =
  'https://interviewing.io/blog/our-business-depends-on-having-the-best-interviewers-so-we-built-an-interviewer-rating-system-and-you-can-too';

const DedicatedCoachingCalloutCard = (props: DedicatedCoachingCalloutCardPropsType) => (
  <div className="flex flex-col lg:flex-row flex-wrap pb-4">
    <div className="mr-[0.5625rem] flex-[1_1_65%]">
      <div className="mx-0 leading-relaxed text-[22px] font-medium">
        We have the best and most experienced interviewers. Guaranteed.
      </div>
      <div className="mt-2 text-[16.5px] text-gray-800 font-normal leading-relaxed">
        Our interviewers are top-rated Senior/Staff/Principal-level engineers at FAANG and FAANG+ companies. We{' '}
        <a href={BLOG_POST_URL} className="underline" target="_blank" rel="noopener noreferrer">
          obsessively track calibration & engagement metrics for every interviewer
        </a>{' '}
        to make sure that you always get matched with the best. If you're not thrilled, you get your money back.
        <br />
        <br />
        Get {props.discount}% off when you buy 4 or more sessions.
      </div>
    </div>
    <div className="flex-[1_1_20%] mt-4 lg:mt-0">
      <div className="p-4 text-[16px] bg-customLightBlue rounded-lg">
        <div className="text-base font-medium">Need help preparing for Google, Facebook, or Amazon?</div>
        <div className="text-base mt-1">
          Our{' '}
          <a className="underline" href="dashboard/interviewee/dedicated-coaching/">
            dedicated coaching packages
          </a>{' '}
          are the single best thing you can do to secure an offer.
        </div>
      </div>
    </div>
  </div>
);

export default DedicatedCoachingCalloutCard;
