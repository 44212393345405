import Pseudonym from '../Onboarding/modalFrames/Pseudonym/Pseudonym';

const SettingsPseudonymWrapper = () => (
  <div className="border-[1px] rounded-md border-gray-300 p-4">
    <h2 className="pl-1 font-semibold text-sm/6 text-gray-800">Your Anonymous Handle</h2>
    <Pseudonym />
  </div>
);

export default SettingsPseudonymWrapper;
