import { useEffect } from 'react';

import { hooks } from 'front-chat-sdk';
import { useUser } from '../userContext';

const { baseUrl, frontChat: frontChatConfig } = window.CONFIG;

const CHAT_ALLOW_PATTERNS = [
  '^/dashboard/interviewee(/.*)?$',
  '^/schedule/view-schedule$',
  '^/history$',
  '^/dashboard/interviewee/dedicated-coaching$',
  '^/salary-negotiation$',
  '^/company-introductions$',
  '^/showcase$',
  '^/referrals$',
].map((pattern) => new RegExp(pattern));

/**
 * The useFrontChat hook shows/hides the Front Chat Widget
 *
 * - is intended for use at the top-level of the React app
 * - includes a set of allowed url paths and uses this to determine if the chat widget should be displayed
 * - sets an event listener on the browser history to detect and respond to Angular and React client-side navigation
 */
export const useFrontChat = () => {
  const { initialize, isInitialized, frontChat } = hooks.useFrontChatBoot(document.body);
  const { user } = useUser();

  if (!frontChatConfig.enable) {
    return;
  }

  useEffect(() => {
    const initializeChat = () => {
      if (!initialize) {
        return;
      }

      try {
        if (!CHAT_ALLOW_PATTERNS.some((pattern) => pattern.test(window.location.pathname))) {
          if (isInitialized) {
            frontChat('shutdown');
          }
          return;
        }
        let chatInitData;
        if (user) {
          chatInitData = {
            chatId: frontChatConfig.id,
            userId: user._id,
            userHash: user.frontChatHash,
            name: `${user.firstName} ${user.lastName}`,
            contact: {
              email: user.email,
              customFields: {
                userId: user._id.toString(),
                superAdminLink: `${baseUrl}/admin/users?userId=${user._id.toString()}`,
              },
            },
            autoTrackSessions: false,
          };
        } else {
          chatInitData = {
            chatId: frontChatConfig.id,
            autoTrackSessions: false,
          };
        }
        window.Rollbar.debug(JSON.stringify(chatInitData));
        initialize(chatInitData);
      } catch (err) {
        window.Rollbar.error(err);
      }
    };

    const handleUrlChange = () => {
      initializeChat();
    };

    window.addEventListener('popstate', handleUrlChange);
    const originalPushState = history.pushState;
    history.pushState = function historyPushState(...args) {
      originalPushState.apply(this, args);
      handleUrlChange();
    };

    initializeChat();

    return () => {
      window.removeEventListener('popstate', handleUrlChange);
      history.pushState = originalPushState;
    };
  }, [isInitialized, initialize, frontChatConfig.id, frontChatConfig.identitySecret, user]);
};
