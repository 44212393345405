import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useUser } from '../../userContext';
import SettingsLabel from '../SettingsLabel/SettingsLabel';
import { useServerErrors } from '../../hooks/useServerErrors';
import SettingsFormError from '../SettingsFormError/SettingsFormError';

interface ChangeEmailForm {
  newEmail: string;
}

const ChangeEmail = () => {
  const { user } = useUser();
  const { dispatch } = useToasts();
  const { serverErrors, generalError, processErrors, clearErrors } = useServerErrors();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm<ChangeEmailForm>();
  const inputValue = watch('newEmail', '');

  const changeEmailMutation = useMutation(
    async (data: ChangeEmailForm) => await axios.put(`/api/users/me/email`, data).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Email change has been requested, please check your email to confirm.',
          },
        });
        clearErrors();
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          processErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ChangeEmailForm) => {
    changeEmailMutation.mutate(data);
  };

  return (
    <div className="items-left mt-4 flex flex-col rounded-md bg-white border-[1px] shadow-sm py-4 px-4">
      <h2 className="text-sm/6 font-semibold text-gray-800">Email Address</h2>
      {user?.email && <p>Your current email is {user.email}</p>}
      <form className="w-100 mt-2" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="sm:col-span-4">
          <SettingsLabel htmlFor="newEmail">New Email</SettingsLabel>
          <div className="">
            <input
              id="newEmail"
              name="newEmail"
              type="email"
              autoComplete="email"
              placeholder="Enter new email address"
              className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6
                ${inputValue ? 'text-gray-900' : 'text-gray-400'}
                ${errors.newEmail ? 'outline-red-500' : 'outline-gray-300'}`}
              {...register('newEmail', {
                required: 'New email is required.',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Entered value does not match email format',
                },
              })}
            />
            <div className="my-2 h-2">
              {(errors.newEmail || serverErrors.newEmail) && (
                <SettingsFormError message={errors.newEmail?.message || serverErrors.newEmail} />
              )}
            </div>
            {generalError && <SettingsFormError message={generalError} />}
          </div>
        </div>

        <SubmitButton isLoading={changeEmailMutation.isLoading} defaultText="Change Email" loadingText="Changing..." />
      </form>
    </div>
  );
};

export default ChangeEmail;
