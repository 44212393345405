import { useEffect, useState } from 'react';
import { RefreshIcon } from '@heroicons/react/outline';
import { useUser } from '../../../../userContext';

const Pseudonym = () => {
  const { user, generatePseudonym } = useUser();
  const [loadingPseudonym, setLoadingPseudonym] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleGeneratePseudonym = async () => {
    setLoadingPseudonym(true);
    setError(null);
    try {
      await generatePseudonym();
    } catch (err) {
      window?.Rollbar?.error('Error generating pseudonym:', err);
      setError('Failed to generate pseudonym. Please try again.');
    } finally {
      setLoadingPseudonym(false);
    }
  };

  useEffect(() => {
    if (!user.pseudonym) {
      handleGeneratePseudonym();
    }
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div>
        <p className="pl-1">What everyone you interview with will see</p>
      </div>
      <div className="my-3">
        <span className="shadow-sm bg-gray-50 w-full sm:text-sm rounded-md flex flex-col sm:flex-row justify-between items-center">
          <p className="py-2 sm:pl-4 text-lg font-medium">{loadingPseudonym ? 'Generating...' : user?.pseudonym}</p>
          <div className="sm:pr-4">
            <div
              tabIndex={0}
              className="flex text-blue-700 float-right cursor-pointer"
              onClick={!loadingPseudonym ? handleGeneratePseudonym : undefined}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !loadingPseudonym) handleGeneratePseudonym();
              }}
            >
              <span>{loadingPseudonym ? 'Generating...' : 'Generate new handle'}</span>
              <RefreshIcon height={18} className={`ml-2 ${loadingPseudonym ? 'animate-spin' : ''}`} />
            </div>
          </div>
        </span>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default Pseudonym;
