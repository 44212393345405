import Integrations from '../Integrations/Integrations';
import SettingsPseudonymWrapper from '../SettingsPseudonymWrapper/SettingsPseudonymWrapper';
import UserPreferences from '../UserPreferences/UserPreferences';

const SettingsAccount = () => (
  <div className="mt-4">
    <div className="pb-2">
      <SettingsPseudonymWrapper />
    </div>
    <div className="pb-2">
      <Integrations />
    </div>
    <div className="pb-2">
      <UserPreferences />
    </div>
  </div>
);

export default SettingsAccount;
