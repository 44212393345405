interface SettingsFormErrorMessageProps {
  message?: string;
}

const SettingsFormError = ({ message }: SettingsFormErrorMessageProps) => {
  if (!message) return null;

  return <p className="text-xs text-red-500">{message}</p>;
};

export default SettingsFormError;
