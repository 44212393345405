import { ReactNode } from 'react';

type SettingsLabelProps = {
  htmlFor: string;
  className?: string;
  children?: ReactNode;
};

const SettingsLabel = ({ htmlFor, className, children }: SettingsLabelProps) => (
  <label htmlFor={htmlFor} className={`${className} block text-sm/6 text-gray-900`}>
    {children}
  </label>
);

export default SettingsLabel;
