import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useUser } from '../../userContext';
import axios from '../../utils/axios';
import CardBrandIcon from '../CardBrandIcon/CardBrandIcon';
import { useToasts } from '../ToastContext';
import { useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { AddCardModal } from '../Modals/AddCardModal';
import NiceModal from '@ebay/nice-modal-react';
import { YesNoActionModal } from '../Modals/YesNoActionModal';

type PaymentMethod = {
  id: string;
  last4: string;
  _isDefault: boolean;
  _isExpired: boolean;
  brand: 'visa' | 'mastercard' | 'amex' | 'discover';
};

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

const PaymentInformation = () => {
  const { user } = useUser();
  const { dispatch } = useToasts();
  const queryClient = useQueryClient();
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();

  const { data: paymentMethods, isLoading } = useQuery(
    ['payment-methods', user._id],
    () => axios.get('/api/orders/paymentMethods').then((response) => response.data),
    {
      enabled: !!user._id,
    }
  );

  const removePaymentMethodMutation = useMutation(
    ['remove-payment-method'],
    (paymentMethodId: string) =>
      axios
        .post('/api/orders/detachPaymentMethod', { paymentMethodId: paymentMethodId })
        .then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your payment method has been removed.',
          },
        });
        setServerErrors([]);
        queryClient.invalidateQueries({ queryKey: ['payment-methods', user._id] });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const makePaymentMethodDefaultMutation = useMutation(
    ['make-payment-method-default'],
    (paymentMethodId: string) =>
      axios
        .post('/api/orders/setDefaultPaymentMethod', { paymentMethodId: paymentMethodId })
        .then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your payment method has been made the default.',
          },
        });
        setServerErrors([]);
        queryClient.invalidateQueries({ queryKey: ['payment-methods', user._id] });
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  return (
    <>
      <div className="flex flex-row space-between items-center border-b-2 w-100">
        <h2 className="flex-grow py-4 text-sm/6 font-semibold text-gray-800">Payment Methods</h2>
        <div className="ml-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 cursor-pointer transition-transform duration-300 ease-in-out hover:rotate-90 hover:text-blue-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
            onClick={() => NiceModal.show(AddCardModal, { dispatch })}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
      </div>
      {isLoading && <>loading...</>}
      {!isLoading && (
        <div className="mt-2">
          {paymentMethods.map((method: PaymentMethod) => (
            <div key={method.id} className="flex mt-2 flex-row items-center justify-between">
              <div className="flex items-center">
                <CardBrandIcon className="mr-2" brand={method.brand} />
                <span>
                  Card ending {method.last4} {method._isDefault && '(Default)'} {method._isExpired && '(Expired)'}
                </span>
              </div>
              <div className="flex space-x-4">
                {!method._isDefault && paymentMethods.length != 1 && (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 cursor-pointer text-blue-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                      onClick={() =>
                        NiceModal.show(YesNoActionModal, {
                          title: 'Make Payment Default',
                          handleAction: () => makePaymentMethodDefaultMutation.mutate(method.id),
                          message: 'Are you sure you want to make this payment method the default?',
                          afterClose: () => {},
                          rejectActionLabel: 'Cancel',
                          confirmActionLabel: 'Make Default',
                        })
                      }
                      data-tooltip-id="make-payment-method-default"
                      data-tooltip-content="Make payment method the default"
                      data-tooltip-place="bottom"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                      />
                    </svg>
                    <Tooltip id="make-payment-method-default" opacity={1} style={{ backgroundColor: '#000' }} />
                  </>
                )}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 cursor-pointer text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  onClick={() =>
                    NiceModal.show(YesNoActionModal, {
                      title: 'Remove Payment Method',
                      handleAction: () => removePaymentMethodMutation.mutate(method.id),
                      message: 'Are you sure you want to remove this payment method?',
                      rejectActionLabel: 'Cancel',
                      confirmActionLabel: 'Confirm',
                    })
                  }
                  data-tooltip-id="remove-payment"
                  data-tooltip-content="Remove payment method"
                  data-tooltip-place="bottom"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
                <Tooltip id="remove-payment" opacity={1} style={{ backgroundColor: '#000' }} />
              </div>
            </div>
          ))}
          <div className="min-h-6 py-1">
            {serverErrors &&
              serverErrors.map((error, index) => (
                <ul key={index} className="text-sm text-red-500">
                  <li>{error.msg}</li>
                </ul>
              ))}
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentInformation;
