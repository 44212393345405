import { InformationCircleIcon } from '@heroicons/react/outline';
import { useUser } from '../../../userContext';
import { SingleActionModal } from '../../Modals/SingleActionModal';
import NiceModal from '@ebay/nice-modal-react';
import EligibilityCriteriaList from '../EligibilityCriteriaList/EligibilityCriteriaList';
import EligibilityCriteriaDetails from '../EligibilityCriteriaDetails';

const showEligibilityInfoModal = () => {
  NiceModal.show(SingleActionModal, {
    actionLabel: 'OK',
    title: 'Eligibility for company intros',
    message: <EligibilityCriteriaDetails />,
  });
  window.angular
    ?.element(document.body)
    .injector()
    .get('AnalyticsService')
    .track(window.CONFIG.const.analytics.eventNames.EVENT_REVENUE_COMPANY_INTRO_INELIGIBLE_SHOW_INFO);
};

function EligibilityPanel() {
  const { user } = useUser();

  return (
    <div className="rounded-lg bg-customLightBlue p-4">
      {user && (
        <>
          <p className="text-base font-medium">
            {user.isCompanyIntroductionsEligible
              ? "You've unlocked intros at top companies"
              : "You're not currently eligible for company intros"}
          </p>
          <EligibilityCriteriaList user={user} />
          {!user.isCompanyIntroductionsEligible && (
            <div className="w-100 mt-1 text-right text-base font-light">
              <button onClick={showEligibilityInfoModal}>
                Eligibility requirements
                <InformationCircleIcon className="ml-1 inline h-4 w-4 text-gray-400" aria-hidden="true" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EligibilityPanel;
