import { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from '../../utils/axios';
import { useMutation } from '@tanstack/react-query';
import SubmitButton from '../SubmitButton/SubmitButton';
import { TUser } from '../../types/User';

interface ResetPasswordForm {
  email: string;
  password: string;
  hash: string;
}

type ResponseError = {
  type: string;
  value: string;
  msg: string;
  path: string;
  location: string;
};

type ResetPasswordProps = {
  userResolutionResult: {
    user: TUser;
    hash: string;
  };
};
const ResetPassword = ({ userResolutionResult }: ResetPasswordProps) => {
  const { user, hash } = userResolutionResult;
  const [serverErrors, setServerErrors] = useState<ResponseError[]>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordForm>({
    defaultValues: {
      email: user?.email,
    },
  });

  const resetPasswordMutation = useMutation(
    async (data: ResetPasswordForm) =>
      await axios.put(`/api/users/reset-password`, data).then((response) => response.data),
    {
      onSuccess: (token: string) => {
        if (window?.angular?.element(document.body).injector().get('Auth')) {
          window.angular
            ?.element(document.body)
            .injector()
            .get('Auth')
            .authenticate(token)
            .then((authenticated: boolean) => {
              if (authenticated) {
                window.location.href = '/';
              }
            });
        }
        setServerErrors([]);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          setServerErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ResetPasswordForm) => {
    data = { ...data, hash: hash };
    resetPasswordMutation.mutate(data);
  };

  return (
    <div className="flex items-center justify-center mx-auto">
      <div className="mx-auto flex max-w-lg flex-col items-center rounded-lg bg-white p-6 shadow-md mt-32 sm:mt-42">
        <h2 className="text-sm/6 font-bold text-gray-800 mb-4">Forgot Password?</h2>
        <form className="w-full sm:w-[500px] px-4" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="sm:col-span-4">
            <label htmlFor="newEmail" className="block text-sm/6 font-medium text-gray-900">
              Email
            </label>
            <div className="">
              <input
                id="newEmail"
                name="newEmail"
                type="email"
                autoComplete="email"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.email ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
              />
            </div>
          </div>
          <div className="pt-4">
            <label htmlFor="newPassword" className="block text-sm/6 font-medium text-gray-900">
              New Password
            </label>
            <div className="">
              <input
                type="password"
                name="Password"
                id="Password"
                autoComplete="new-password"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1  placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.password ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('password', {
                  required: 'Password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters.',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Password cannot be longer than 100 characters.',
                  },
                })}
              />
            </div>
          </div>
          <div className="h-4">
            <div>
              <p className="text-sm text-red-500">{errors.password && errors.password.message}</p>
            </div>
            <p className="text-sm text-red-500">{errors.email && errors.email.message}</p>
            {serverErrors &&
              serverErrors.map((error, index) => (
                <p key={index} className="text-sm text-red-500">
                  {error.msg}
                </p>
              ))}
          </div>

          <SubmitButton
            isLoading={resetPasswordMutation.isLoading}
            defaultText="Update Password"
            loadingText="Updating..."
          />
        </form>
      </div>
    </div>
  );
};
export default ResetPassword;
