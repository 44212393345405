import ChangeEmail from '../ChangeEmail/ChangeEmail';
import ChangePassword from '../ChangePassword/ChangePassword';

const EmailPassword = () => (
  <>
    <div className="pb-6">
      <ChangeEmail />
    </div>
    <div className="pb-12">
      <ChangePassword />
    </div>
  </>
);

export default EmailPassword;
