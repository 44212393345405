import { useState } from 'react';
import { Round, ROUND_STATE } from '../../../types/RoundTypes';
import { StringHelper } from '../../../utils/StringHelper';
import { RoundHelper } from '../../../utils/RoundHelper';
import useInterval from '../../../hooks/useInterval';
import { showAddToCalendarModal, getCalendarLinks } from '../CalendarUtils/CalendarUtils';
import { Tooltip } from 'react-tooltip';

type DateAndCountdownProps = {
  round: Round;
  lowercase: boolean;
  hasCalendarSync: boolean;
  isCurrent: boolean;
};

const getDateString = (round: Round, roundTimeStatus: ROUND_STATE, lowercase: boolean): string => {
  let dateString = '';
  switch (roundTimeStatus) {
    case 'IS_CURRENT': {
      dateString = 'Right Now!';
      break;
    }
    case 'IS_SOON': {
      dateString =
        'in ' + StringHelper.formatCountdown((new Date(round.start).getTime() - Date.now()) / 1000, true, true);
      break;
    }
    case 'IS_TODAY': {
      dateString = 'Today';
      break;
    }
    case 'IS_TOMORROW': {
      dateString = 'Tomorrow';
      break;
    }
    case 'IS_IN_FUTURE': {
      lowercase = false;
      dateString = StringHelper.formatDateToCustomString(round.start, 'EEEE, MMMM D');
      break;
    }
    default: {
      dateString = '...loading';
    }
  }
  if (lowercase) {
    dateString = dateString.toLowerCase();
  }
  return dateString;
};

export default function DateAndCountdown({ round, lowercase, hasCalendarSync, isCurrent }: DateAndCountdownProps) {
  const [roundTimeStatus, setRoundTimeStatus] = useState<ROUND_STATE>(RoundHelper.getRoundTimeStatus(round));
  const [dateString, setDateString] = useState<string>();
  const [googleLink, appleLink] = getCalendarLinks(round);

  useInterval(() => {
    setRoundTimeStatus(RoundHelper.getRoundTimeStatus(round));
    setDateString(getDateString(round, roundTimeStatus, lowercase));
  }, 500);

  if (roundTimeStatus != 'IS_CURRENT' && roundTimeStatus != 'IS_SOON') {
    const formattedTime = new Date(round.start).toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return (
      <div
        data-tooltip-id={!isCurrent && !hasCalendarSync ? 'calendar-sync-tooltip' : null}
        data-tooltip-content={!isCurrent && !hasCalendarSync ? 'Add to calendar' : null}
        data-tooltip-place={!isCurrent && !hasCalendarSync ? 'top' : null}
        className={`${!hasCalendarSync ? 'pointer _underline-dotted' : ''}`}
      >
        <div onClick={() => showAddToCalendarModal(hasCalendarSync, googleLink, appleLink)}>
          <span className="text-bold">{dateString}</span>
          <span> at </span>
          <span className="text-bold nowrap">{formattedTime}</span>
        </div>
        <Tooltip id="calendar-sync-tooltip" />
      </div>
    );
  }
  return <strong>{dateString}</strong>;
}
