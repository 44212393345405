import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useUser } from '../../userContext';
import axios from '../../utils/axios';
import { useToasts } from '../ToastContext';
import SubmitButton from '../SubmitButton/SubmitButton';
import SettingsLabel from '../SettingsLabel/SettingsLabel';
import { useServerErrors } from '../../hooks/useServerErrors';
import SettingsFormError from '../SettingsFormError/SettingsFormError';

interface ChangePasswordForm {
  oldPassword: string;
  newPassword: string;
}

const ChangePassword = () => {
  const userId = useUser()?.user?._id;
  const { dispatch } = useToasts();
  const { serverErrors, generalError, processErrors, clearErrors } = useServerErrors();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChangePasswordForm>();

  const changePasswordMutation = useMutation(
    async (data: ChangePasswordForm) =>
      await axios.put(`/api/users/${userId}/password`, data).then((response) => response.data),
    {
      onSuccess: () => {
        dispatch({
          type: 'addToast',
          toastContent: {
            primaryMessage: 'Success!',
            secondaryMessage: 'Your password has been changed.',
          },
        });
        clearErrors();
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (error: any) => {
        if (error.response?.data?.errors) {
          processErrors(error.response.data.errors);
        }
      },
    }
  );

  const onSubmit = (data: ChangePasswordForm) => {
    changePasswordMutation.mutate(data);
  };

  return (
    <div className="items-left mt-4 flex flex-col rounded-lg bg-white border-[1px] shadow-sm py-4 px-4">
      <h2 className="text-sm/6 font-semibold text-gray-800">Change Password</h2>
      <form className="w-100" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="">
          <div>
            <SettingsLabel htmlFor="oldPassword">Current Password</SettingsLabel>
            <div className="">
              <input
                type="password"
                name="oldPassword"
                id="oldPassword"
                autoComplete="old-password"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1  placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.oldPassword || serverErrors.oldPassword ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('oldPassword', { required: 'Current password is required' })}
              />
              <div className="my-2 h-2">
                {(errors.oldPassword || serverErrors.oldPassword) && (
                  <SettingsFormError message={errors.oldPassword?.message || serverErrors.oldPassword} />
                )}
              </div>
            </div>
          </div>

          <div className="mt-2">
            <SettingsLabel htmlFor="newPassword">New Password</SettingsLabel>
            <div className="">
              <input
                type="password"
                name="newPassword"
                id="newPassword"
                autoComplete="new-password"
                className={`block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1  placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6 ${
                  errors.newPassword || serverErrors.newPassword ? 'outline-red-500' : 'outline-gray-300'
                }`}
                {...register('newPassword', {
                  required: 'New password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters.',
                  },
                  maxLength: {
                    value: 100,
                    message: 'Password cannot be longer than 100 characters.',
                  },
                })}
              />
              <div className="my-2 h-2">
                {(errors.newPassword || serverErrors.newPassword) && (
                  <SettingsFormError message={errors.newPassword?.message || serverErrors.newPassword} />
                )}
              </div>
            </div>
          </div>
        </div>
        {generalError && <SettingsFormError message={generalError} />}

        {/* Submit Button */}
        <div className="flex flex-col">
          <SubmitButton
            isLoading={changePasswordMutation.isLoading}
            defaultText="Change Password"
            loadingText="Changing..."
          />
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
