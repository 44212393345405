interface ErrorProps {
  isRescheduleCooldown?: boolean;
}

const Error = ({ isRescheduleCooldown = false }: ErrorProps) => (
  <div className="mt-3 rounded-md bg-red-50 px-4 py-2 sm:mt-0">
    {isRescheduleCooldown ? (
      <div className="space-y-1">
        <h3 className="text-sm font-medium text-red-800">Oops! Too many reschedule attempts.</h3>
        <p className="text-sm text-red-800">A session can only be rescheduled once per hour. Please try again later.</p>
      </div>
    ) : (
      <h3 className="text-sm font-medium text-red-800">Oops! Please try again.</h3>
    )}
  </div>
);

export default Error;
